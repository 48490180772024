export default class DateHelper {
    static yesterdayDate() {
        const currentDate = new Date();
        let yesterdayDate = new Date(currentDate);
        yesterdayDate.setDate(currentDate.getDate() - 1)

        const year = yesterdayDate.getFullYear();
        const month = String(yesterdayDate.getMonth() + 1).padStart(2, '0');
        const day = String(yesterdayDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    static todayDate() {
        const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    static sevenDaysFromNow() {
        const currentDate = new Date();
        let futureDate = new Date(currentDate);
        futureDate.setDate(currentDate.getDate() + 7);

        const year = futureDate.getFullYear();
        const month = String(futureDate.getMonth() + 1).padStart(2, '0');
        const day = String(futureDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
}